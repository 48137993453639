export type UserRole = 'customer' | 'consultant' | 'admin';

export interface Auth0User
{
    id : string;
    emailAddress : string;
    role : UserRole;
}

export interface Auth0State
{
    authenticated : boolean;
    user : Auth0User | null;
}

export const SET_USER = 'AUTH0_SET_USER';
export const UNSET_USER = 'AUTH0_UNSET_USER';

interface SetUserAction
{
    type : typeof SET_USER;
    payload : {
        user : Auth0User;
    };
}

interface UnsetUserAction
{
    type : typeof UNSET_USER;
}

export type Auth0ActionTypes = SetUserAction | UnsetUserAction;
