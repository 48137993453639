import React, {Component, ReactNode} from 'react';
import {AppState} from '../../../redux';
import {connect} from 'react-redux';
import {Auth0User} from '../../../redux/auth0/types';
import ConsultantDashboard from './ConsultantDashboard';
import CustomerDashboard from './CustomerDashboard';

interface StateProps
{
    auth0User : Auth0User;
}

type Props = StateProps;

class Dashboard extends Component<Props>
{
    public render() : ReactNode
    {
        const {auth0User} = this.props;

        if (auth0User.role === 'consultant' || auth0User.role === 'admin') {
            return <ConsultantDashboard/>;
        }

        return <CustomerDashboard/>;
    }
}

const mapStateToProps = (state : AppState) : StateProps => ({
    auth0User: state.auth0.user!,
});

export default connect<StateProps, null, null , AppState>(
    mapStateToProps
)(
    Dashboard
);
